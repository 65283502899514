import React, { createContext, useContext, useEffect, useState } from 'react';
import { Switch, Route, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EndingPage from './pages/EndingPage';
import LandingPage from './pages/LandingPage';
import EndingNoRedirectPage from './pages/EndingNoRedirectPage';
import api from '~api';
import SurveyPage from './pages/SurveyPage';
import Await from './pages/Await';
import AlreadyRegistered from './pages/AlreadyRegistered';
import ErrorPage from './pages/ErrorPage';

ReactGA.initialize(process.env.ANALYTICS_TRACKING_CODE);

const CampaignContext = createContext();

const Flow = () => {
  const [campaign, setCampaign] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [provider, setProvider] = useState();
  const [leadId, setLeadId] = useState();

  const { url } = useRouteMatch();
  const { campaignId } = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (loading) return;
    if (url !== location.pathname + location.search) return;

    if (campaign.Landing) {
      history.push(`${url}/landing`);
    } else {
      history.push(`${url}/survey`);
    }
  }, [loading, campaign.Landing]);

  useEffect(() => {
    (async () => {
      try {
        const [
          campaignResponse,
          landingResponse,
          endingResponse,
          endingNoRedirectResponse,
          termsModalResponse,
          surveysResponse,
        ] = await Promise.all([
          api.getCampaign(campaignId),
          api.getCampaignLandingList(campaignId),
          api.getCampaignEndingList(campaignId),
          api.getCampaignEndingNoRedirectList(campaignId),
          api.getCampaignTermsModalList(campaignId),
          api.getCampaignSurveysList(campaignId),
        ]);

        if (!termsModalResponse.list[0].Id) throw new Error('Terms modal missing');
        if (!surveysResponse.list.length) throw new Error('Surveys missing');

        setCampaign({
          ...campaignResponse,
          Landing: landingResponse?.list[0]?.Id,
          Ending: endingResponse?.list[0]?.Id,
          'Ending no redirect': endingNoRedirectResponse?.list[0]?.Id,
          'Terms Modal': termsModalResponse.list[0].Id,
          Survey: surveysResponse.list.map(({ Id }) => Id),
        });
      } catch (err) {
        setError(err.statusCode);
        console.warn(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        campaignLoading: loading,
        campaignError: error,
        setError,
        url,
        userData,
        setUserData,
        provider,
        setProvider,
        leadId,
        setLeadId,
      }}
    >
      <Switch>
        <Route path={`${url}/landing`}>
          <LandingPage url={url} />
        </Route>
        <Route path={`${url}/survey`}>
          <SurveyPage />
        </Route>
        <Route path={`${url}/await`}>
          <Await />
        </Route>
        <Route path={`${url}/ending`}>
          <EndingPage url={url} />
        </Route>
        <Route path={`${url}/already-registered`}>
          <AlreadyRegistered url={url} />
        </Route>
        <Route path={`${url}/ending-no-redirect`}>
          <EndingNoRedirectPage />
        </Route>
        <Route path={`${url}/error`}>
          <ErrorPage errorCode={error} />
        </Route>
      </Switch>
    </CampaignContext.Provider>
  );
};

export const useCampaign = () => {
  const context = useContext(CampaignContext);
  if (context === undefined) {
    throw new Error('useCampaign must be used within a CampaignContext');
  }
  return context;
};

export default Flow;
